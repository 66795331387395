import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import {
  useNavigate
} from "react-router-dom";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

const URL = "https://fsbcy2if63c6mc6f7eys2wlldi0shopn.lambda-url.us-east-1.on.aws/";

// const form = {
//   name: "",
//   email: "",
//   city: "",
//   address: "",
//   phone: "",
//   message: "",
// };


const form = {
  name: "",
  email: "",
  city: "Calgary",
  address: "",
  phone: "",
  message: "",
};


export default ({
  subheading = "Contact Us",
  // heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  heading = <><span tw="text-primary-500">Free Estimate</span><wbr/> Request Form</>,
  description = "Feel free to get in touch with us.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = false,
  preEmail = "",
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState(preEmail);
  const [address, setAddress] = React.useState('');
  const [city, setCity] = React.useState('Calgary');
  const [phone, setPhone] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [text, setText] = React.useState(submitButtonText);
  const navigate = useNavigate();

  setEmail(preEmail);
  
  const handleChangeName = event => {
    setName(event.target.value);
    setText(submitButtonText);
    form.name = event.target.value;
    console.log('value is:', event.target.value);
  };
  const handleChangeEmail = event => {
    setEmail(event.target.value);
    setText(submitButtonText);
    form.email = event.target.value;
    console.log('value is:', event.target.value);
  };
  const handleChangeAddress = event => {
    setAddress(event.target.value);
    setText(submitButtonText);
    form.address = event.target.value;
    console.log('value is:', event.target.value);
  };
  const handleChangeCity = event => {
    setCity(event.target.value);
    setText(submitButtonText);
    form.city = event.target.value;
    console.log('value is:', event.target.value);
  };
  const handleChangePhone = event => {
    setPhone(event.target.value);
    setText(submitButtonText);
    form.phone = event.target.value;
    console.log('value is:', event.target.value);
  };
  const handleChangeMessage = event => {
    setMessage(event.target.value);
    setText(submitButtonText);
    form.message = event.target.value;
    console.log('value is:', event.target.value);
  };
  
  

  const handleClick = event => {
    setText(submitButtonText);
    event.preventDefault();

    // 👇️ value of input field
    console.log('handleClick 👉️', form);
    let qs = "?";
    qs += form.name ? "name=" + encodeURIComponent(form.name) : "";
    qs += form.email ? "&email=" + encodeURIComponent(form.email) : "";
    qs += form.city ? "&city=" + encodeURIComponent(form.city) : "";
    qs += form.address ? "&address=" + encodeURIComponent(form.address) : "";
    qs += form.phone ? "&phone=" + encodeURIComponent(form.phone) : "";
    qs += form.message ? "&message=" + encodeURIComponent(form.message) : "";
    if (form.name) {
      // fetch("https://api.example.com/items")
      fetch(URL + qs)
      .then(res => res.json())
      .then(
        (result) => {
          console.log("========", result)
          navigate("/thank-you");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("++++++", error)
          setText("Error, try again");
        }
      )

    //  navigate("/thank-you");
    }
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form>
              <Input type="text" name="name" placeholder="Name" onChange={handleChangeName}
                  value={name}/>
              <Input type="email" name="email" placeholder="Email Address" onChange={handleChangeEmail}
                  value={email}/>
              <Input type="text" name="address-level2" placeholder="City" onChange={handleChangeCity}
                  value={city}/>
              <Input type="text" name="street-address" placeholder="Address" onChange={handleChangeAddress}
                  value={address}/>
              <Input type="phone" name="tel" placeholder="Phone number" onChange={handleChangePhone}
                  value={phone}/>
              <Textarea name="message" placeholder="Message" onChange={handleChangeMessage}
                  value={message}/>
              <SubmitButton type="button" onClick={handleClick}>{text}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
