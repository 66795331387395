import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter";
import Header from "components/headers/light.js";
// import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";

// import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomerLoveIconImage from "images/simple-icon.svg";

// const Subheading = tw.span`uppercase tracking-wider text-sm`;

// import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";


// import global from "globals";
import TwoColHorizontalWithButton from "components/features/TwoColWithTwoHorizontalFeaturesAndButton";
import donna from "images/donna-calgary-painters.jpeg";

// const FeatureList = tw.ul`mt-12 leading-loose`;
// const Feature = tw.li`flex items-center`;
// const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
// const FeatureText = tw.p`ml-2 font-medium text-gray-700`;

// const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
// const Column = tw.div``;
// const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;

const Image = styled.img(props => [
props.imageRounded && tw`rounded`,
props.imageBorder && tw`border`,
props.imageShadow && tw`shadow`,
props.inline && tw`inline`
]);




const features = [
    {
        title: "Our guidelines:", 
        list: ["Honesty.", "Professionalism.", "Sticking to a schedule.", "Flawless work."]
    }
];


export default () => {
  return (
    <AnimationRevealPage disabled>
        <Header roundedHeaderButton={true}/>
      <TwoColHorizontalWithButton subheading="About Us" 
      heading={(
        <>
          Hi, I'm <span tw="text-primary-500">Donna Christianson</span>
        </>
      )}
      image={(<Image src={donna} imageBorder={false} imageShadow={true} imageRounded={true} alt="Donna, Owner The Calgary Painters" inline={true}/>)}
      description1={(
        <>
          I have been painting homes and businesses in Alberta <span tw="text-primary-500">for 20 years</span> for 20 years. I'm an expert with the Roller & Brush and some people say I can do magic while holding a sprayer or repairing drywalls. I paint interior & exterior for residential and commercial clients. I am committed to providing the best service in all of Calgary and surrounding areas. I <span tw="text-primary-500">always show up on time</span> & finish the job on time, and I always clean up.
          <br></br>
          <span tw="text-primary-500">I promise to make you a satisfied client.</span>
          </>
      )}
      description={(
        <>
       <div tw="mb-4">
          The Calgary painters works in Calgary & area. We are experts in residential and commercial interior and exterior painting and related trades. We are the best painting contractors in Calgary, AB and we deliver every time the best  in any aspect  - service, workmanship, quality & materials.
          </div>
        </>

      )}
      guidelines={features}
      description2={(
        <>
          <p tw="mb-4">The Calgary painters offer complete interior and exterior painting  services for both residential and commercial locations. The Calgary painters do anything – from custom finishes for new homes throw repaints, staining  – with design and budget planning.</p>
          <p>
Treat your home with a makeover today. The Calgary painters offer many options that will have you falling in love with your home all over again. Transform your walls. The Calgary painters does it all…from budgeting and planning to the perfect results.</p>
          </>
      )}
      
      ></TwoColHorizontalWithButton>
      {/* <Container>
      <ContentWithVerticalPadding>
          <Row>
            <TextColumn>

            
            <FeatureList>
                        {features.map((feature, index) => (
                        <Feature key={index}>
                            <FeatureIcon />
                            <FeatureText>{feature}</FeatureText>
                        </Feature>
                        ))}
                    </FeatureList>
                    </TextColumn>
                    </Row>
                    </ContentWithVerticalPadding>
                    </Container> */}
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
};
