import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
// import { css } from "styled-components/macro"; //eslint-disable-line
// import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter";
import Header from "components/headers/light.js";
// import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";
import { useSearchParams } from 'react-router-dom';

// import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomerLoveIconImage from "images/simple-icon.svg";

// const Subheading = tw.span`uppercase tracking-wider text-sm`;

// import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";


// import global from "globals";
// import TwoColHorizontalWithButton from "components/features/TwoColWithTwoHorizontalFeaturesAndButton";
// import paints from "images/benjamin-moore.png";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm";

// const FeatureList = tw.ul`mt-12 leading-loose`;
// const Feature = tw.li`flex items-center`;
// const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
// const FeatureText = tw.p`ml-2 font-medium text-gray-700`;

// const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
// const Column = tw.div``;
// const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;

// const Image = styled.img(props => [
// props.imageRounded && tw`rounded`,
// props.imageBorder && tw`border`,
// props.imageShadow && tw`shadow`,
// props.inline && tw`inline`
// ]);


export default () => {

    const [searchParams] = useSearchParams();

  return (
    <AnimationRevealPage disabled>
        <Header roundedHeaderButton={true}/>
        <ContactUsForm preEmail={searchParams.get("email") || ""}></ContactUsForm>
      <Footer />
    </AnimationRevealPage>
  );
};
