import logo from "images/the-calgary-painters-logo.png";
import donna from "images/donna-calgary-painters.jpeg";
import review1 from "images/review1.png";

const global = {
    name: "The Calgary Painters",
    logo: logo,
    tag: "Great Painting Services",
    donna: donna,
    reviews: {
        one: review1
    }
}
export default global;