import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
// import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { ReactComponent as Donna } from "images/donna-owner.svg";
import { ReactComponent as Phone } from "images/calgary-painters-phone.svg";
import { ReactComponent as Email } from "images/calgary-painters-email.svg";
// import { ReactComponent as Donna } from "images/person.svg";
// import { ReactComponent as Phone}  from "images/phone.svg";
// import { ReactComponent as Email}  from "images/envelope-at.svg";
// import global from "globals";
// import donna from "images/donna-calgary-painters.jpeg";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";


const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-3/12 flex-shrink-0 relative`;
const ImageColumn2 = tw(Column)`md:w-2/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-9/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:order-last`
]);
const TextColumn2 = styled(Column)(props => [
  tw`md:w-10/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:order-last`
]);

// const Image = styled.img(props => [
//   props.imageRounded && tw`rounded`,
//   props.imageBorder && tw`border`,
//   props.imageShadow && tw`shadow`
// ]);

// const DecoratorBlob = tw(
//   SvgDotPattern
// )`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`;

const TextContent = tw.div`text-center md:text-left`;
const TextContent2 = tw.div`text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.div`mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const Description1 = tw.div` md:flex py-2 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const Features = tw.div`mx-auto md:mx-0  max-w-xs lg:max-w-none`;
const Feature = tw.div`mt-10 lg:mt-8 flex items-center md:items-start flex-col md:mr-8 last:mr-0`;

const FeatureHeadingContainer = tw.div`flex items-center`;
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
  ${props => [
    props.iconRoundedFull && tw`rounded-full`,
    props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`
  ]}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const FeatureHeading = tw.div`ml-3 font-bold text-xl`;


const FeatureList = tw.ul`mt-2 leading-loose`;
const Feature2 = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;

// const FeatureDescription = tw.div`mt-4 text-center md:text-left text-gray-600 leading-relaxed`;

// const PrimaryButton = styled(PrimaryButtonBase)(props => [
//   tw`mt-12 text-sm inline-block mx-auto md:mx-0`,
//   props.buttonRounded && tw`rounded-full`
// ]);

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  image = "",
  description1 = "",
  description2 = "",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  guidelines = [],
  primaryButtonText = "Learn More",
  primaryButtonUrl = "https://timerse.com",
  imageSrc = TeamIllustrationSrc,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = false,
  textOnLeft = true,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    {
      Icon: Donna,
      title: "Donna Christianson"
    },{
      Icon: Phone,
      title: "403-606-0712"
    },{
      Icon: Email,
      title: "info@thecalgarypainters.com"
    }

  ];

  if (!features) features = defaultFeatures;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Subheading>Contacts</Subheading>
          {/* <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} /> */}
          {/* {showDecoratorBlob && <DecoratorBlob />} */}
          <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureHeadingContainer>
                    <FeatureIconContainer
                      iconFilled={iconFilled}
                      iconRoundedFull={iconRoundedFull}
                      css={feature.iconContainerCss || iconContainerCss}
                    >
                      {<feature.Icon />}
                    </FeatureIconContainer>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                  </FeatureHeadingContainer>
                  {/* <FeatureDescription>{feature.description}</FeatureDescription> */}
                </Feature>
              ))}
            </Features>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description1>
              <ImageColumn2>{image}</ImageColumn2>
              <TextColumn2 textOnLeft={false}>
                <TextContent2>
                  {description1}
                </TextContent2>
              </TextColumn2>
            </Description1>
            <Description>
              {description}
            </Description>
            {(guidelines.length ? 
            guidelines.map((features, index) => ( 
              <Description key={index}>
              <Subheading>{features.title}</Subheading>
              <FeatureList>
              {(features.list.map((feature, index2) => (
                        <Feature2 key={index2}>
                            <FeatureIcon />
                            <FeatureText>{feature}</FeatureText>
                        </Feature2>
                      )))}
                    </FeatureList>
            </Description>    )) : "")}

            {(description2 ? <Description>
              <Heading>We Deliver</Heading>
              {description2}
            </Description> : "")}
            

            {/* <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
