import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";


import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import InteriorImage from "images/interior-painting-calgary.svg";
import ExteriorImage from "images/support-icon.svg";
import MoreImage from "images/customize-icon.svg";
// import FastIconImage from "images/fast-icon.svg";
// import ReliableIconImage from "images/reliable-icon.svg";
// import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const cardsArr = [
  {
    imageSrc: InteriorImage,
    title: "Interior",
    description: "We know how hard it is to let tradesman hanging inside your home for few days.  For that reason, we will take an extra care while working during the project & only high quality workmanship will work for you. After preparing the house & painting it, we will make sure that everything is back in its place and clean the house for the fresh new look."
  },
  { imageSrc: ExteriorImage, 
    title: "Exterior",
    description: `Every successful exterior project starts with good preparation. We know that this is the most important factor if you want the paint to stick. Our experience leads us to the perfect prep work.
  After the prep phase done, we focus on applying the paint or stain in a fast & efficient way which ensure the best look after drying.` },
  { imageSrc: MoreImage, 
    title: "More",
    description: `
    We are proudly provide wide array of services. From house painting to commercial & industrial locations, from the smallest job to the biggest.
    
    Just tell us what you want - We are here for you.` }
];

export default ({ cards = cardsArr, heading = "Amazing Features", subheading = "a", description = "Confidence Is The Key" }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  
  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
