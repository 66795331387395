import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter";
import Header from "components/headers/light.js";
// import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";

// import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomerLoveIconImage from "images/simple-icon.svg";

// const Subheading = tw.span`uppercase tracking-wider text-sm`;

// import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";


// import global from "globals";
import TwoColHorizontalWithButton from "components/features/TwoColWithTwoHorizontalFeaturesAndButton";
import paints from "images/benjamin-moore.png";

// const FeatureList = tw.ul`mt-12 leading-loose`;
// const Feature = tw.li`flex items-center`;
// const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
// const FeatureText = tw.p`ml-2 font-medium text-gray-700`;

// const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
// const Column = tw.div``;
// const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;

const Image = styled.img(props => [
props.imageRounded && tw`rounded`,
props.imageBorder && tw`border`,
props.imageShadow && tw`shadow`,
props.inline && tw`inline`
]);



export default () => {
  return (
    <AnimationRevealPage disabled>
        <Header roundedHeaderButton={true}/>
      <TwoColHorizontalWithButton subheading="Paints" 
      heading={(
        <>
          We use only the best paints
        </>
      )}
      image={(<Image src={paints} imageBorder={false} imageShadow={true} imageRounded={true} alt="benjamin-moore paints" inline={true}/>)}
    //   image={(<Image src={donna} imageBorder={false} imageShadow={true} imageRounded={true} alt="Donna, Owner The Calgary Painters" inline={true}/>)}
      description1={(
        <>
          </>
      )}
      description={(
        <>
       <div tw="mb-4">
       Color, technology, innovation, and leadership define Benjamin Moore & Co. For more than a century, succeeding in these areas has motivated all who work here, and continues to inspire and direct our growth today. Since 1883, we’ve created and sold products that preserve, protect, and beautify our homes, buildings, and structures. We excel at creating a portfolio of products that our customers desire and demand.
<br></br><br></br>

With our acquisition in 2000 by Berkshire Hathaway, Benjamin Moore joined a family of companies under the direction of Warren Buffett, which embodies success and is distinguished by sustainable brands that shine through the clutter of competition. We continue to be at the forefront of product innovation, with a commitment to research and development unrivaled in the architectural coatings industry. At Benjamin Moore laboratories and manufacturing facilities, our scientists and technicians work to exceed the already superior application, performance properties, and environmental safety of our products. Our commitment to color inspires an ongoing search for the perfect hues, the ones that lead our customers to claim: “that’s the color I want.” Independent retailers – our primary distribution channel – do more than sell our products. With our help, they provide the service and tools that give customers confidence about their product choices, color selections, and their ability to get the best results possible. And we embrace a company tradition of giving back. Across North America, Benjamin Moore is preserving historic structures, protecting the environment, and supporting programs that enrich our communities.
<br></br><br></br>


More than a century of commitment to producing superior products and unrivaled colors has its rewards: Our paint is the number one choice of interior designers. People who choose our products know they’ll get the inspiration and support to make their vision reality. They depend on our extraordinary colors and product performance. Their trust defines Benjamin Moore.
<br></br><br></br>


<strong>Then</strong>
<br></br>

﻿In 1883, Benjamin Moore and his brother Robert opened Moore Brothers in Brooklyn, New York. True entrepreneurs, they started with $2,000 and one product, “Moore’s® Prepared Calsom Finish,” which was sold exclusively through independent retailers. Moore Brothers built their company on Benjamin Moore’s belief in “the exercise of intelligent industry in the spirit of integrity,” and a dedication to manufacturing excellence and quality.

<br></br><br></br>


<strong>Now</strong>
<br></br>

Today, Benjamin Moore & Co., a Berkshire Hathaway company, is a high-performing, innovative manufacturer and retailer of quality coatings and provider of related goods and services for decoration and preservation. We continue as a leader in the architectural coatings industry with products that set new standards of excellence. We manufacture at 7 plants, distribute from 22 facilities, and sell the finest paints, stains, and finishes through a network of more than 4,000 independent retailers across North America.
<br></br><br></br><br></br><br></br>
====================================<br></br><br></br><br></br><br></br>

Cloverdale Paint<br></br>
http://www.cloverdalepaint.com/<br></br>
ICI Paints<br></br><br></br>

<strong>Committed to Exceptional People, Products and Service
</strong>
Cloverdale Paint has had a special relationship with its customers ever since the company was founded in 1933. That relationship was built on a commitment to exceptional quality and superior customer service. From humble beginnings on a farm near Cloverdale, BC, we’ve grown into one of North America’s largest regional paint and coatings manufacturers. Cloverdale Paint is Proudly Canadian!
<br></br><br></br>

<strong>History</strong>
<br></br>

Cloverdale Paint was founded in 1933 by a farmer, chemist and paint manufacturer Rudy Henke. Business grew steadily as people discovered the quality and personal service that Rudy offered. In 1946, Hunter Vogel recognized the opportunity in Rudy’s paint business and purchased a 50% share in the company becoming co-founder of Cloverdale Paint.
<br></br><br></br>
Hunter’s drive and determination were the fuel for rapid growth. Our current Chairman is Wink Vogel, Hunter’s son. During the 1950′s and 60′s the powerhouse father and son team opened branches throughout the Fraser Valley and Vancouver. From 1968 to 1975 the company again doubled in size.
<br></br><br></br>
<strong>We Build Our Reputation on Listening!
</strong>
<br></br>
It has been said that no one ever “listened themselves out of a customer”. Cloverdale’s commitment to creating long-term trusted relationships with a growing legion of customers was company co-founder Hunter Vogel’s mission. Wink Vogel has built on his father’s legacy by instilling the company with a commitment to sound professional and personal values. “One way we’ve kept our company strong is by recruiting the sons and daughters of current and past employees. This infuses the company with new ideas and talent while maintaining a rich, family tradition of craftsmanship that’s passed on from generation to generation.”
<br></br><br></br>
<strong>Cloverdale and the Environment
</strong>
<br></br>
Cloverdale Paint is committed to reducing or eliminating any harmful impact on the environment from our products. To fulfill this commitment Cloverdale has developed one of the industry’s largest selections of water-based and low Volatile Organic Compound (VOC) paints and coatings.
<br></br><br></br>
Cloverdale was one of the founding members of the BC Paint Care Association which operates paint recycling depots throughout British Columbia. Cloverdale Paint is also committed to reducing or eliminating any harmful impact on the environment from our operations.
          </div>
        </>

      )}
     
      
      ></TwoColHorizontalWithButton>
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
};
