import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
// import { css } from "styled-components/macro"; //eslint-disable-line
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter";
import Header from "components/headers/light.js";
// import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";

// import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomerLoveIconImage from "images/simple-icon.svg";

// const Subheading = tw.span`uppercase tracking-wider text-sm`;

// import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";


// import global from "globals";
import TwoColHorizontalWithButton from "components/features/TwoColWithTwoHorizontalFeaturesAndButton";
// import donna from "images/donna-calgary-painters.jpeg";

// const FeatureList = tw.ul`mt-12 leading-loose`;
// const Feature = tw.li`flex items-center`;
// const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
// const FeatureText = tw.p`ml-2 font-medium text-gray-700`;

// const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
// const Column = tw.div``;
// const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;

// const Image = styled.img(props => [
// props.imageRounded && tw`rounded`,
// props.imageBorder && tw`border`,
// props.imageShadow && tw`shadow`,
// props.inline && tw`inline`
// ]);

const features = [
    {
        title: "The Calgary Painters are proud to offer a wide array of services, including:", 
        list: ["Free Estimates – same day scheduling.", "Free Paint color / design consultation.", "Flexible scheduling – 7 days a week.", "High quality and clean work."]
    },
    {
        title: "Interior", 
        list: ["Free Estimates – same day scheduling.", "Free Paint color / design consultation.", "Flexible scheduling – 7 days a week.", "High quality and clean work."]
    },
    {
        title: "Exterior", 
        list: ["Free Estimates – same day scheduling.", "Free Paint color / design consultation.", "Flexible scheduling – 7 days a week.", "High quality and clean work."]
    }
];

export default () => {
  return (
    <AnimationRevealPage disabled>
        <Header roundedHeaderButton={true}/>
      <TwoColHorizontalWithButton subheading="Services" 
      heading={(
        <>
          
        </>
      )}
    //   image={(<Image src={donna} imageBorder={false} imageShadow={true} imageRounded={true} alt="Donna, Owner The Calgary Painters" inline={true}/>)}
      description1={(
        <>
          </>
      )}
      description={(
        <>
       <div tw="mb-4">
       The Calgary Painters are dedicated to providing you with a level of service that is unmatched. Our attention to details and eye for design ensures an enjoyable experience.
          </div>
        </>

      )}
      guidelines={features}
     
      
      ></TwoColHorizontalWithButton>
      {/* <Container>
      <ContentWithVerticalPadding>
          <Row>
            <TextColumn>

            
            <FeatureList>
                        {features.map((feature, index) => (
                        <Feature key={index}>
                            <FeatureIcon />
                            <FeatureText>{feature}</FeatureText>
                        </Feature>
                        ))}
                    </FeatureList>
                    </TextColumn>
                    </Row>
                    </ContentWithVerticalPadding>
                    </Container> */}
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
};
